import { Tabs, Table, Tag, Pagination, Drawer, AutoComplete } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import api from '../../../controllers/ApiController';
import Container from '../../../components/Container';
import Button from '../../../components/Button';

const { Option } = AutoComplete;


const Search = ({ data }) => {

  const navigate = useNavigate();
  const [search, setSearch] = useState([]);

  const onSearch = (term) => {
    api.post('/v1/admin/search', { term }).then(res => {
      setSearch(res.data);
    })

    if (!term) {
      navigate('/admin/music/')
    }

  }

  const onSelect = (e) => {
    navigate('/admin/music/?' + e);
  }

  return <AutoComplete
    style={{
      width: 200,
    }}
    onSelect={onSelect}
    onSearch={onSearch}
    placeholder="Search anything"
  >
    {search.map((result) => (
      <Option key={result.value} value={result.value}>
        {result.label}
      </Option>
    ))}
  </AutoComplete>
}

function App({ admin }) {

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [view, setView] = useState();
  const [tab, setTab] = useState('drafts');
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    api.get(`/v1/admin/contracts/status/` + tab).then(res => {
      setData(res.data)
      setLoading(false);
    }).catch(e => {

    });
  }, [tab]);

  const onRefresh = (id) => {
    api.get('/v1/admin/contracts/' + id).then(res => {
      setView(res.data);
    })
  }

  const onClickPaid = () => {
    api.post('/v1/admin/contracts/payout/mark', { id: view?.id }).then(res => {
      onRefresh(view?.id);
    })
  }

  const onSendContract = () => {
    api.get('/v1/admin/contracts/docusign/create/' + view?.id).then(res => {
      onRefresh(view?.id);
    })
  }

  const onReject = () => {
    api.post('/v1/admin/contracts/reject/', { id: view?.id }).then(res => {
      onRefresh(view?.id);
    })
  }


  const columns = [
    {
      title: 'Aritst',
      dataIndex: 'artist',
      key: 'title',
      render: (text, record) => <div>{`${record.User.name} (${record.firstName} ${record.lastName})`}</div>,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (text, record) => <div>${text}</div>,
    },
    {
      title: 'Status',
      key: 'tags',
      dataIndex: 'tags',
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {

            let color;

            switch (tag) {
              case "rejected":
                color = 'red';
                break;
              case "review":
                color = 'orange';
                break;
              case "docusign: sent":
                color = 'orange';
                break;
              case "docusign: completed":
                color = 'green';
                break;
              case "finished":
                color = 'green';
                break;
              default:
                color = 'grey';
            }

            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>

            );
          })}
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return <Button onClick={() => onRefresh(record.id)}>View</Button>
      },
    },
  ];

  const getTags = (v) => {
    const tags = [];

    if (v.status === 0) {
      tags.push('draft');
    }

    if (v.status === 1) {
      tags.push('review');
    }

    if (v.docusignStatus === 'sent') {
      tags.push('docusign: sent');
    }

    if (v.docusignStatus === 'completed') {
      tags.push('docusign: completed');
    }

    if (v.status === 3) {
      tags.push('finished');
    }

    return tags;

  }

  const prepare = () => {
    return data.map(v => {
      return {
        key: v.id,
        ...v,
        tags: getTags(v)
      }
    })
  }




  const tabs = [
    { label: 'Drafts', key: 'review', children: '' }, // remember to pass the key prop
    { label: 'Waiting', key: 'waiting', children: '' },
    { label: 'Completed', key: 'completed', children: '' },
  ];

  console.log(view);


  const tabs2 = [
    {
      label: 'Details', key: 'tabs2-details', children: <div>
        <div>Tracks: <b>{view?.Offers?.length}</b></div>
        <div>Verifed: </div>
        <div>Persona ID: {view?.verifId}</div>
        <div>DocuSign: <Tag color={view?.docusignStatus === 'completed' ? 'green' : 'red'}>{view?.docusignStatus}</Tag></div>
        <div>Amount: ${view?.price}</div>
        <div>Payment Method: {view?.Payout?.paymentMethod}</div>
        <div>Payment Details: <b>{view?.Payout?.paymentDetails}</b></div>
        <div className="flex gap-4 mt-4">
          {view?.status && view?.status !== 3 && <Button onClick={onReject} red block>Reject</Button>}
          {(!view?.docusignStatus && view?.status === 1) && <Button onClick={onSendContract} block>Send contract</Button>}
          {(view?.docusignStatus === 'completed' && view?.status === 2) && <Button onClick={onClickPaid}>Mark as paid</Button>}
          {(view?.docusignStatus !== 'completed' && view?.status === 2) && <Button onClick={() => onRefresh(view?.id)}>Refresh</Button>}
        </div>


      </div>
    }, // remember to pass the key prop
    {
      label: 'Tracks', key: 'tabs2-waiting', children: <table>
        <tbody>
          {view?.Offers && view.Offers.map(offer => <tr>
            <td style={{ width: '100%' }}>{offer.Track.title}</td>
            <td>${offer.price}</td>
          </tr>)}
          <tr style={{ backgroundColor: 'lightgrey' }}>
            <td></td>
            <td><b>${view?.price}</b></td>
          </tr>
        </tbody>
      </table>
    },
  ];

  return (
    <Container title="Contracts" extra={<Search />}>
      <Tabs items={tabs} onChange={setTab} />
      <Table columns={columns} dataSource={prepare(data)} pagination={{
        position: ['none', 'none'], pageSize: 100
      }} />
      <br />
      <Pagination defaultCurrent={page} total={data.count} pageSize={20} onChange={setPage} />
      <Drawer title={view ? `${view.User.name} (${view.firstName} ${view.lastName})` : null} placement="right" onClose={() => setView(null)} open={!!view} width={420}>
        <Tabs items={tabs2} />
      </Drawer>
    </Container>
  );
}

export default App;
